import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import routesMap from '../../../Routes';

import fabrics from '../../../assets/tilliUptrade/fabrics.jpg';
import './style.css';

import {
  FlexContainer as StyledFlexContainer,
  Button as StyledButton,
} from './styledComponents';

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey7};
  margin-bottom: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
`;

const Image = styled.img`
  max-width: 656px;
  max-height: 568px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    max-width: unset;
    max-height: unset;
    object-fit: cover;
  }
`;

const Line = styled.div`
  width: 54px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.navy};
  margin-right: 20px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    display: none;
  }
`;

const TextContainer = styled.div`
  max-width: 538px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 74px;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-left: 0px;
    width: 100%;
    max-width: unset;
  }
`;

const Title = styled.p`
  max-width: 429px;
  font-family: Libre Baskerville;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Text = styled.p`
  width: 100%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.navy};
  margin-bottom: 50px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 40px;
  }
`;

const Button = styled(StyledButton)`
  align-self: center;
`;

const FlexContainer = styled(StyledFlexContainer)`
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    ${({ mobilePaddingPx }) => mobilePaddingPx && `padding: ${mobilePaddingPx};`}
    width: 100%;
    box-sizing: border-box;
  }
`;

const HomeRevaluation = ({ title, texts, buttonText }) => (
  <MainContainer>
    <Image alt="Rouleaux de tissus" src={fabrics} />
    <FlexContainer column mobilePaddingPx="0px 18px">
      <FlexContainer style={{ alignItems: 'center' }}>
        <Line />
        <Title>{title}</Title>
      </FlexContainer>
      <TextContainer>
        <Text>
          {texts.map((text) => (
            <>
              {text}
              <br />
            </>
          ))}
        </Text>
        <Button onClick={() => navigate(routesMap.ProductsSelectorPage.url)}>{buttonText}</Button>
      </TextContainer>
    </FlexContainer>
  </MainContainer>
);

HomeRevaluation.propTypes = {
  title: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
};

HomeRevaluation.defaultProps = {
  title: 'Revaloriser les stocks dormants',
  texts: [
    `Uptrade source en France et en Europe les stocks dormants des fabricants et des marques
      de mode pour les mettre à la disposition de ceux qui savent leur donner une seconde vie.`,
    '',
    `Nous vous offrons la garantie d’une meilleure revalorisation et d’un tissu sur-mesure
      pour toutes vos idées de création à travers un service accessible, rapide et sans minimum d’achat.`,
  ],
  buttonText: 'Voir tous les tissus',
};

export default HomeRevaluation;
