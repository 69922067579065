import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  ${({ column }) => column && 'flex-direction: column;'}
`;

export const WhiteBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 97px;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 0px 18px;
    &:not(:last-of-type) {
      margin-bottom: 70px;
    }
  }
`;

export const Button = styled.button`
  padding: 0px 38px;
  height: 50px;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.navy};
  cursor: pointer;
  background-color: ${({ theme, isNavy }) => isNavy ? theme.colors.navy : 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme, isNavy }) => theme.colors[isNavy ? 'white' : 'navy']};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
  }
`;
