import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Container, Content } from '../components/home/v2/styledComponents';
import Header from '../components/home/v3/Header';
import BenefitsBanner from '../components/home/v3/BenefitsBanner';
import ACouturierAtHome from '../components/home/v3/ACouturierAtHome';
import LandingServices from '../components/home/v3/LandingServices';
import FeedbacksBannerAds from '../components/home/v3/FeedbacksBannerAds';
import ReferrerBanner from '../components/home/ReferrerBanner';
import FAQAds from '../components/home/v3/FAQAds';
import MenuBar from '../components/home/MenuBar';
import PartnersPager from '../components/home/v3/PartnersPager';
import FootNote from '../components/LandingPage/FootNote';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';

import { getAlterationAltTagMainLanding } from './LandingLocalityFormatter';
import HomeRevaluation from '../components/home/TilliUptrade/HomeRevaluation';

class LandingMainPageTemplate extends React.Component {
  componentDidMount() {
    const { data: { landingsMainsJson } } = this.props;
    const { landingPage, brand } = landingsMainsJson;
    if (landingPage) {
      localStorage.setItem('landingPage', landingPage);
      localStorage.setItem('landingPage_expiryKey', (new Date()).toISOString());
    }
    if (brand) { localStorage.setItem('brand', brand); }
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  renderHeader() {
    const { data: { landingsMainsJson } } = this.props;
    const {
      fabric, preFilledSteps, landingPage, headerTitle, backgroundImage, mobileBackgroundImage,
    } = landingsMainsJson;
    const title = headerTitle || 'Nos couturiers se déplacent chez vous, pour vos retouches';
    const photoVersion = landingPage || fabric;
    return (
      <Header
        title={title}
        hideNote
        smallTitle
        titleLeft
        photoVersion={photoVersion}
        curtainsNote={fabric === 'curtains'}
        preFilledSteps={preFilledSteps}
        backgroundImage={backgroundImage?.publicURL}
        mobileBackgroundImage={mobileBackgroundImage?.publicURL}
      />
    );
  }

  renderHomeRevaluation() {
    const { data: { landingsMainsJson } } = this.props;
    const { slug } = landingsMainsJson;
    const decoVersion = !!(slug === 'HomePageDecoCreation');
    if (!decoVersion) return null;
    return (
      <HomeRevaluation
        tille="Vous n’avez pas de tissus ?"
        texts={[
          `Nous collaborons avec Uptrade pour vous proposer un vaste catalogue de tissus sourcés
            dans les stocks dormants des grandes marques.`,
          `Initialement réservé aux professionnels, ce service est la garantie d’une meilleure revalorisation
            et d’un tissu sur-mesure pour toutes vos idées de création à travers une solution accessible, rapide, sans minimum d’achat et à des prix abordables.`,
        ]}
        buttonText="J’achète mon tissu"
      />
    );
  }

  render() {
    const { data: { landingsMainsJson }, referrer } = this.props;
    const {
      slug, fabric, preFilledSteps, footNote, breadcrumb, landingPage, customerFeedbacks, brands,
    } = landingsMainsJson;
    const photoVersion = landingPage || fabric;
    const decoVersion = !!(slug === 'HomePageDecoCreation');
    return (
      <Layout routeSlug={slug}>
        <Container>
          <Content full hasNoBanner>
            {referrer && <ReferrerBanner referrer={referrer} />}
            <MenuBar />
            {this.renderHeader()}
            <BenefitsBanner altTag={getAlterationAltTagMainLanding(slug)} />
            <ACouturierAtHome
              photoVersion={photoVersion}
              curtainsVersion={fabric === 'curtains'}
              preFilledSteps={preFilledSteps}
              withBottomContainer={!decoVersion}
            />
            {decoVersion && <LandingServices version="decoCreationVersion" />}
            {this.renderHomeRevaluation()}
            <FeedbacksBannerAds curtainsVersion={fabric === 'curtains'} customerFeedbacks={customerFeedbacks} />
            <FAQAds curtainsVersion={fabric === 'curtains'} preFilledSteps={preFilledSteps} isSEO />
            {!decoVersion && <PartnersPager curtainsVersion={fabric === 'curtains'} brands={brands} />}
            {footNote
              && (
                <FootNote
                  title={footNote.title}
                  paragraph={footNote.paragraph}
                  breadcrumbIntro={footNote.breadcrumbIntro.paragraph}
                  breadcrumbIntroLinks={footNote.breadcrumbIntro.links}
                  links={footNote.links}
                  showFAQ={footNote.showFAQ}
                  itemListElement={breadcrumb}
                />
              )}
            {decoVersion && <PartnersPager brands={brands} />}
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

LandingMainPageTemplate.propTypes = {
  data: PropTypes.shape({
    landingsMainsJson: PropTypes.shape({
      landingPage: PropTypes.string,
      brand: PropTypes.string,
      slug: PropTypes.string,
      fabric: PropTypes.string,
      headerTitle: PropTypes.string,
      backgroundImage: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      mobileBackgroundImage: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      preFilledSteps: PropTypes.shape({}),
      customerFeedbacks: PropTypes.arrayOf(PropTypes.shape({
        photo: PropTypes.shape({
          publicURL: PropTypes.string,
        }),
        name: PropTypes.string,
        rating: PropTypes.number,
        jobDescription: PropTypes.string,
        feedback: PropTypes.string,
      })),
      brands: PropTypes.arrayOf(PropTypes.shape({
        img: PropTypes.shape({
          publicURL: PropTypes.string,
        }),
        index: PropTypes.number,
      })),
      footNote: PropTypes.shape({
        title: PropTypes.string,
        paragraph: PropTypes.string,
        breadcrumbIntro: PropTypes.shape({
          paragraph: PropTypes.string,
          links: PropTypes.arrayOf(PropTypes.string),
        }),
        links: PropTypes.arrayOf(PropTypes.string),
        showFAQ: PropTypes.bool,
      }),
      breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
  referrer: PropTypes.shape({}),
};

LandingMainPageTemplate.defaultProps = {
  referrer: null,
};
export default LandingMainPageTemplate;

export const landingMainPageQuery = graphql`
query landingsMainsQuery($path: String!) {
  landingsMainsJson(path: { eq: $path }) {
    slug
    path
    landingPage
    headerTitle
    backgroundImage {
      publicURL
    }
    mobileBackgroundImage {
      publicURL
    }
    preFilledSteps {
      selectedCategory
      selectedPiece
      selectedTypeOfWork
    }
    brand
    fabric
    customerFeedbacks {
      photo {
        publicURL
      }
      name
      rating
      jobDescription
      feedback
    }
    brands {
      img {
        publicURL
      }
      index
    }
    footNote {
      title
      paragraph
      links
      breadcrumbIntro {
        paragraph
        links
      }
      showFAQ
    }
    breadcrumb {
      name
      item
    }
  }
}`;
