import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UpTitle, Title2 } from './styledComponents';
import dressingImg from '../../../assets/services/dressing.jpg';
import collierImg from '../../../assets/services/necklace.jpg';
import boutonImg from '../../../assets/services/button.jpg';
import teddyImg from '../../../assets/services/teddy.jpg';
import sofaCreationImg from '../../../assets/services/sofaCreation.jpg';
import plaidCreationImg from '../../../assets/services/plaidCreation.jpg';
import cushionCreationImg from '../../../assets/services/cushionCreation.jpg';
import curtainsAlterationImg from '../../../assets/services/curtainsAlteration.jpg';

const Container = styled.div`
  margin: 74px 0px 78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 30px 0px;
  }
`;

const ServicesContainer = styled.div`
  max-width: 1440px;
  margin: 0px 135px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
    width: 100vw;
  }
`;

const Service = styled.div`
  margin-bottom: 55px;
  box-sizing: border-box;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: calc(100vw - 50px);
    margin: 0px 25px 50px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 413px;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: 170px;
    width: 100%;
  }
`;

const Title = styled.h2`
  align-self: flex-start;
  font-family: Libre Baskerville;
  color: ${({ theme }) => theme.colors.navy};
  line-height: ${({ theme }) => theme.fontSizes.xl};
  font-size: 26px;
  margin-bottom: 14px;
  margin-top: 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
    margin-bottom: 10px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 45px;
  max-width: 584px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-right: 0px;
  }
`;

const TextSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: justify;
  font-family: Roboto;
  color: ${({ theme }) => theme.colors.navy};
  line-height: 25px;
  font-size: 14px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    text-align: left;
  }
`;

const Line = styled.div`
  min-width: 24px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.navy};
  margin: 15px 8px 0px 0px;
`;

const adviceServices = [
  {
    img: dressingImg,
    title: 'Regardons votre dressing',
    imgAlt: 'Une couturière fait une transformation de vêtement',
    // eslint-disable-next-line max-len
    text: 'Savez-vous que l’on ne porte que 30% de son dressing ? Que faire du reste ? Prenez RDV avec l’un de nos Tillistes pour vous aider à repenser tous les vêtements que vous ne portez plus.',
  },
  {
    img: collierImg,
    title: 'Vous avez trouvé une pépite ?',
    imgAlt: 'Retouche de vêtements à domicile',
    // eslint-disable-next-line max-len
    text: 'Suite à votre RDV, vous avez pu grâce à votre couturier vous projeter et identifier des pièces à transformer pour pouvoir les reporter ? Tout comme ce Kimono vintage dont les boutons ne sont plus à vôtre goût.',
  },
  {
    img: boutonImg,
    title: 'Nos conseils pour l’upcycler',
    imgAlt: 'Kimono réajusté par un tailleur',
    // eslint-disable-next-line max-len
    text: 'Laissez-vous guider pour reporter cette veste qui vous tient à coeur, pourquoi ne pas changer les boutons et ajouter de beaux patchs blancs pour un petit coup de fraîcheur ?',
  },
  {
    img: teddyImg,
    title: 'Une nouvelle pièce toute neuve',
    imgAlt: 'Pull remaillé par un tailleur',
    // eslint-disable-next-line max-len
    text: 'Faites du shopping dans votre dressing. Un pull à remailler ? Un tailleur vintage à ajuster ou une veste à upcycler ? Votre couturier vous dira ce qu’il est possible de faire.',
  },
];

const decoCreationServices = [
  {
    img: sofaCreationImg,
    title: 'Retouche et réparation',
    imgAlt: 'Une couturière crée une housse de canapé',
    textIntro: 'Rideaux - Linges de maison - Coussins - Canapé - etc.',
    text: 'Faites facilement retoucher et réparer votre décoration directement à domicile en prenant rendez-vous avec nos artisans couturier.',
  },
  {
    img: plaidCreationImg,
    title: 'Création sur mesure',
    imgAlt: 'Création de plaid ou de couvre-lit par une couturière certifiée',
    textIntro: 'Rideaux - Linges de maison - Coussins - Housse de canapé - Plaid - etc.',
    text: 'Donnez une seconde vie à votre mobilier et embellissez vos intérieurs de façon responsable avec votre Tilliste.',
  },
  {
    img: cushionCreationImg,
    title: 'Upcycling',
    imgAlt: 'Housse de coussin créée à partir de tissus upcyclés',
    textIntro: 'Coussins - Cache pot - Linges de maison - etc.',
    text: 'Nos Tillistes vous accompagnent dans vos projets de transformation pour prolonger la vie de vos éléments de déco et retravailler vos chutes de tissu.',
  },
  {
    img: curtainsAlterationImg,
    title: 'Broderie',
    imgAlt: 'Broderie sur rideaux',
    textIntro: 'Sur tout type de tissu.',
    text: 'Personnalisez votre linge de maison et vos tissus d’ameublement avec des broderies sur-mesure.',
  },
];

const servicesVersion = {
  adviceVersion: adviceServices,
  decoCreationVersion: decoCreationServices,
};

const LandingServices = ({ version }) => (
  <Container>
    <UpTitle>Nos services</UpTitle>
    <Title2 marginBottom={100}>Que peut-on faire pour vous ?</Title2>
    <ServicesContainer>
      {(servicesVersion[version] || []).map((service) => (
        <Service key={service.title}>
          <Image
            src={service.img}
            alt={service.imgAlt}
            loading="lazy"
          />
          <TextContainer>
            <Line />
            <TextSubContainer>
              <Title>{service.title}</Title>
              {service.textIntro && <Text>{service.textIntro}</Text>}
              <Text>{service.text}</Text>
            </TextSubContainer>
          </TextContainer>
        </Service>
      ))}
    </ServicesContainer>
  </Container>
);

LandingServices.propTypes = {
  version: PropTypes.string.isRequired,
};

export default LandingServices;
